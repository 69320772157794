import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import { OptionsUtils } from 'ts/commons/OptionsUtils';
import type { DashboardDescriptor } from 'typedefs/DashboardDescriptor';
import type { DashboardDescriptorBase } from 'typedefs/DashboardDescriptorBase';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';

/** Utility functions related to dashboards. */
export class DashboardUtils {
	/** The option name (server-side) under which the last accessed dashboard is stored (per user). */
	public static readonly LAST_ACCESSED_DASHBOARD_OPTION = 'dashboard.lastAccessed';

	/** The special key under which the last accessed dashboard for 'all dashboards' (= across projects) is stored. */
	public static readonly LAST_ACCESSED_DASHBOARD_FOR_ALL_PROJECTS_KEY = '#-#all-projects#-#';

	/**
	 * Returns the qualified name of the given dashboard.
	 *
	 * @param dashboardDescriptor The descriptor of the dashboard.
	 * @returns The qualified name of the dashboard.
	 */
	public static getQualifiedName(dashboardDescriptor: DashboardDescriptor): string {
		return DashboardUtils.buildQualifiedDashboardName(dashboardDescriptor.owner, dashboardDescriptor.name!);
	}

	/**
	 * Builds the qualified name of the given dashboard.
	 *
	 * @param owner The owner of the dashboard.
	 * @param name The name of the dashboard.
	 * @returns The qualified name of the dashboard.
	 */
	public static buildQualifiedDashboardName(owner: string, name: string): string {
		return owner + '/' + name;
	}

	/** Returns the dashboard name part of a qualified dashboard name. */
	public static getDashboardNameFromQualifiedName(qualifiedDashboardName: string): string {
		asserts.assert(qualifiedDashboardName.includes('/'), 'Qualified dashboard name is missing delimiter.');
		return qualifiedDashboardName.substring(qualifiedDashboardName.indexOf('/') + 1);
	}

	/**
	 * Fetches the default dashboards per project from the server.
	 *
	 * @returns The projects (keys) with their respective default dashboard (values).
	 */
	public static loadLastAccessedDashboardsByProject(perspectiveContext: PerspectiveContext): Map<string, string> {
		const userOptions = perspectiveContext.userInfo.userOptions;
		const option = userOptions[DashboardUtils.LAST_ACCESSED_DASHBOARD_OPTION];
		return OptionsUtils.createOptionMapFromString(option.projectDashboardMapping);
	}

	/**
	 * Checks if the given dashboard is shared to anyone.
	 *
	 * @returns Boolean value indicating if this is a shared dashboard.
	 */
	public static isSharedDashboard(dashboard: DashboardDescriptor): boolean {
		const isShared =
			(dashboard.userAccessEntries && dashboard.userAccessEntries.length > 0) ||
			(dashboard.groupAccessEntries && dashboard.groupAccessEntries.length > 0) ||
			(dashboard.projectAccessEntries && dashboard.projectAccessEntries.length > 0);
		if (isShared == null) {
			return false;
		}
		return isShared;
	}

	/** Returns all referenced projects in the given dashboard descriptor. */
	public static getReferencedProjects(dashboardDescriptor: DashboardDescriptorBase): string[] {
		const projects: string[] = [];
		// @ts-ignore
		dashboardDescriptor.descriptor.widgets.forEach(
			(widget: { Path: { project: string } | null; project: string }) => {
				// Includes call on list might be expensive, but should be ok because we don't expect dashboards with more
				// than a handful of referenced projects.
				if (widget.Path && !projects.includes(widget.Path.project)) {
					projects.push(widget.Path.project);
				}
				if (widget.project && !projects.includes(widget.project)) {
					projects.push(widget.project);
				}
			}
		);
		return projects;
	}

	/** Returns an object map from qualified dashboard name to the dashboard descriptor for the given dashbards. */
	public static getDashboardsByName(
		dashboards: UserResolvedDashboardDescriptor[]
	): Record<string, UserResolvedDashboardDescriptor> {
		const dashboardsByName: Record<string, UserResolvedDashboardDescriptor> = {};
		for (const dashboard of dashboards) {
			const qualifiedDashboardName = DashboardUtils.getQualifiedName(dashboard);
			dashboardsByName[qualifiedDashboardName] = dashboard;
		}
		return dashboardsByName;
	}
}
