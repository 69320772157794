import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import { StringBuffer } from 'ts-closure-library/lib/string/stringbuffer';
import { StringUtils } from './StringUtils';

/** Utility functions related to options. */
export class OptionsUtils {
	/** Separates keys and their values in string representations of maps */
	public static KEY_VALUE_SEPARATOR = '>#>';

	/** Separates individual entries in string representations of maps */
	public static ENTRY_SEPARATOR = '!,!';

	/**
	 * Converts the given string representation of a map (@see #createStringFromOptionMap) back to an actual map.
	 *
	 * For null/empty input strings, an empty map is returned.
	 */
	public static createOptionMapFromString(rawOptionString: string): Map<string, string> {
		const resultMap = new Map();

		// The second case means that the old (global) default dashboard was saved, which is no longer supported.
		if (
			StringUtils.isEmptyOrWhitespace(rawOptionString) ||
			!rawOptionString.includes(OptionsUtils.KEY_VALUE_SEPARATOR)
		) {
			return resultMap;
		}
		const rawEntries = rawOptionString.split(OptionsUtils.ENTRY_SEPARATOR);
		rawEntries.forEach(rawEntry => {
			const separatorOffset = rawEntry.lastIndexOf(OptionsUtils.KEY_VALUE_SEPARATOR);
			asserts.assert(
				separatorOffset > -1,
				'Entry ' + rawEntry + ' does not match expected format (separator not found)'
			);
			const key = rawEntry.substring(0, separatorOffset);
			const value = rawEntry.substring(separatorOffset + OptionsUtils.KEY_VALUE_SEPARATOR.length);
			resultMap.set(key, value);
		});
		return resultMap;
	}

	/**
	 * Converts the given map to a stringified version, using KEY_VALUE_SEPARATOR and ENTRY_SEPARATOR as separators. For
	 * empty inputs, an empty string is returned.
	 */
	public static createStringFromOptionMap(optionMap: Map<string, string>): string {
		const result = new StringBuffer('');
		let i = 0;
		const entryCount = Array.from(optionMap.keys()).length;
		optionMap.forEach((value, key) => {
			result
				.append(key)
				.append(OptionsUtils.KEY_VALUE_SEPARATOR)
				.append(value || '');
			if (i < entryCount - 1) {
				result.append(OptionsUtils.ENTRY_SEPARATOR);
			}
			i++;
		});
		return result.toString();
	}
}
